import { captureException } from '@sentry/nextjs';
import {
  EventSummary,
  CheckoutSession,
  ContactHostOptions,
  ConfirmCheckoutSessionParams,
  UpdateCheckoutSessionParams,
  UpdateUnauthenticatedCheckoutSessionParams,
  VerifyTicketResultDto,
  SubmitEventRatingBody,
  SuggestedFriendsFromEventDto,
  EventForUser,
  TicketInfoRequiredResponse,
  EventForPublic,
  AnalyticsDto,
  DiscountCodeType,
  DiscountCodeDto,
  EventDiscountCodesDto,
  UpdateDiscountCodeParams,
  DeleteDiscountCodeParams,
  DiscountCodeAnalyticsDto,
  CreateDiscountCodeParams,
  TextAllGuestsParams,
  TextAllGuestsDto,
  PutPayoutDestinationParams,
  EventWaiverDto,
  PostEarlyPayoutParams,
  EventEdit,
  EventCreation,
  TierApprovals,
  TierApprovalAnswer,
  MultiTierCheckoutSession,
  UpdateMultiTierCheckoutSessionParams,
  UpdateUnauthenticatedMultiTierCheckoutSessionParams,
  RequestApprovalParams,
  CreateWaiverDto,
  WaiverDto,
  UpdateWaiverDto,
  GuestEntryList,
  GuestEntryDto,
  TierDto,
  CreateTierDto,
  UpdateTierDto,
  MomentDto,
  CreateMoment,
  EventAnalytics,
  SubmitRequestToPublishFormDto,
  RequestToPublishFormDto,
  RecurringEventDto,
  RecurringFrequency
} from './types';

import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import { GenericSuccessResponse } from 'legacy/common/types/response';
import { TicketDto } from 'legacy/tickets/types';
import { Event } from 'legacy/common/types/models';

export const MAX_PUBLIC_TICKETS = 30;
export const MAX_PRIVATE_TICKETS = 250;
export const MAX_COMPLIMENTARY_TICKETS = 250;

export async function getEvent(eventId: string): Promise<EventForUser> {
  const { data } = await bounceApiClient.get(`/api/events/${eventId}`);

  return data;
}

export async function getRequestToPublishForm(
  formId: string
): Promise<RequestToPublishFormDto> {
  const { data } = await bounceApiClient.get(`/api/forms/${formId}`);

  return data;
}

export async function getRequestToPublishResponse(
  responseId: string
): Promise<SubmitRequestToPublishFormDto> {
  const { data } = await bounceApiClient.get(
    `/api/formResponses/${responseId}`
  );

  return data;
}

export async function contactHost({
  eventId,
  details
}: ContactHostOptions): Promise<Record<string, unknown>> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/service/events/${eventId}/contactHost`,
    data: details
  });

  return data;
}

export interface InviteUsersToEventOptions {
  eventId: string;
  userIds: string[];
}

export interface RefundTicketOptions {
  eventId: string;
  userId: string;
}

export async function inviteUsersToEvent({ eventId, userIds }): Promise<any> {
  return bounceApiClient.post(`/api/events/${eventId}/invites`, {
    friendIds: userIds
  });
}

export async function refundTicket({
  eventId,
  userId
}: RefundTicketOptions): Promise<GenericSuccessResponse> {
  return bounceApiClient.post(`/api/hosts/events/${eventId}/refunds`, {
    userId
  });
}

export async function refundMultiTickets({
  eventId,
  userId
}: RefundTicketOptions): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.post(
    `/api/hosts/events/${eventId}/refunds/multi`,
    {
      userId
    }
  );

  return data;
}

export async function getEventSummary(eventId: string): Promise<EventSummary> {
  const { data } = await bounceApiClient.get(
    `/api/hosts/events/${eventId}/summary`
  );
  return data;
}

export async function getEventAnalytics(
  eventId: string
): Promise<EventAnalytics> {
  const { data } = await bounceApiClient.get(
    `/api/v2/events/${eventId}/analytics`
  );

  return data;
}

export async function exportEventGuests(eventId: string): Promise<Blob> {
  const { data } = await bounceApiClient.get(
    `/api/hosts/events/${eventId}/exportGuests`,
    { responseType: 'blob' }
  );

  return data;
}

export async function exportAnalytics(eventId: string): Promise<Blob> {
  const { data } = await bounceApiClient.get(
    `/api/hosts/events/${eventId}/exportAnalytics`,
    { responseType: 'blob' }
  );

  return data;
}

export async function discountCodeByCode(
  eventId: string,
  code: string
): Promise<DiscountCodeDto> {
  const { data } = await bounceApiClient.get(
    `/api/events/${eventId}/discountCodes/${code}`
  );

  return data;
}

export async function publicDiscountCodeByCode(
  eventId: string,
  code: string
): Promise<DiscountCodeDto> {
  const { data } = await bounceApiClient.get('/checkoutSessions/discountCode', {
    params: {
      eventId,
      code
    }
  });

  return data;
}

export async function verifyCode(
  eventId: string,
  code: string,
  numberOfTickets?: number
): Promise<DiscountCodeType> {
  const params = { code, numberOfTickets };
  const { data } = await bounceApiClient.get(
    `/api/events/${eventId}/verifyCode`,
    { params }
  );

  return data;
}

export async function publicVerifyCode(
  eventId: string,
  code: string,
  numberOfTickets?: number
): Promise<DiscountCodeType> {
  const { data } = await bounceApiClient.post(`/checkoutSessions/verifyCode`, {
    eventId,
    code,
    numberOfTickets
  });

  return data;
}

export async function publicGetEvent(
  eventId: string,
  options?: { shareToken: string }
): Promise<EventForPublic> {
  let params = {};

  if (options?.shareToken) {
    params = {
      shareToken: options.shareToken
    };
  }

  const { data } = await bounceApiClient.get(`/service/events/${eventId}`, {
    params
  });

  return data;
}

export async function getEventWaiver({
  eventId
}: {
  eventId: string;
}): Promise<EventWaiverDto> {
  const { data } = await bounceApiClient.get(`/api/events/${eventId}/waiver`);

  return data;
}

export async function getEventTiers({
  eventId
}: {
  eventId: string;
}): Promise<TierDto[]> {
  const { data } = await bounceApiClient.get(`/api/v2/events/${eventId}/tiers`);

  return data?.items || [];
}

export async function createEventTier(
  eventId: string,
  body: CreateTierDto
): Promise<TierDto> {
  const { data } = await bounceApiClient.post(
    `/api/v2/events/${eventId}/tiers`,
    body
  );
  return data;
}

export async function updateEventTier(
  eventId: string,
  tierId: string,
  body: UpdateTierDto
): Promise<TierDto> {
  const { data } = await bounceApiClient.put(
    `/api/v2/events/${eventId}/tiers/${tierId}`,
    body
  );
  return data;
}

export async function deleteEventTier(
  eventId: string,
  tierId: string
): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.delete(
    `/api/v2/events/${eventId}/tiers/${tierId}`
  );
  return data;
}

export async function getEventTier(
  eventId: string,
  tierId: string
): Promise<TierDto> {
  const { data } = await bounceApiClient.get(
    `/api/v2/events/${eventId}/tiers/${tierId}`
  );
  return data;
}

export async function createEventWaiver(
  eventId: string,
  body: CreateWaiverDto
): Promise<WaiverDto> {
  const { data } = await bounceApiClient.post(
    `/api/v2/events/${eventId}/waivers`,
    body
  );
  return data;
}

export async function updateEventWaiver(
  eventId: string,
  waiverId: string,
  body: UpdateWaiverDto
): Promise<WaiverDto> {
  const { data } = await bounceApiClient.put(
    `/api/v2/events/${eventId}/waivers/${waiverId}`,
    body
  );
  return data;
}

export async function deleteEventWaiver(
  eventId: string,
  waiverId: string
): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.delete(
    `/api/v2/events/${eventId}/waivers/${waiverId}`
  );
  return data;
}

export async function startCheckoutSession(
  eventId: string
): Promise<CheckoutSession> {
  const { data } = await bounceApiClient.post(
    `/api/events/${eventId}/checkout`
  );

  return data;
}

export async function startCheckoutSessionV2(
  eventId: string
): Promise<MultiTierCheckoutSession> {
  const { data } = await bounceApiClient.post(
    `/api/v2/events/${eventId}/checkout`
  );

  return data;
}

export async function startUnauthenticatedCheckoutSession(
  eventId: string
): Promise<CheckoutSession> {
  const { data } = await bounceApiClient.post('/checkoutSessions', { eventId });

  return data;
}

export async function startUnauthenticatedCheckoutSessionV2(
  eventId: string
): Promise<MultiTierCheckoutSession> {
  const { data } = await bounceApiClient.post('/checkoutSessions/v2', {
    eventId
  });

  return data;
}

export async function updateCheckoutSession({
  eventId,
  paymentIntentId,
  body
}: UpdateCheckoutSessionParams): Promise<CheckoutSession> {
  const { data } = await bounceApiClient.put(
    `/api/events/${eventId}/checkout/${paymentIntentId}`,
    body
  );
  return data;
}

export async function updateCheckoutSessionV2({
  eventId,
  paymentIntentId,
  body
}: UpdateMultiTierCheckoutSessionParams): Promise<MultiTierCheckoutSession> {
  const { data } = await bounceApiClient.put(
    `/api/v2/events/${eventId}/checkout/${paymentIntentId}`,
    body
  );
  return data;
}

export async function updateUnauthenticatedCheckoutSession({
  eventId,
  paymentIntentId,
  body
}: UpdateUnauthenticatedCheckoutSessionParams): Promise<CheckoutSession> {
  const { data } = await bounceApiClient.put(
    `/checkoutSessions/${paymentIntentId}`,
    {
      ...body,
      eventId
    }
  );

  return data;
}
export async function updateUnauthenticatedCheckoutSessionV2({
  eventId,
  paymentIntentId,
  body
}: UpdateUnauthenticatedMultiTierCheckoutSessionParams): Promise<MultiTierCheckoutSession> {
  const { data } = await bounceApiClient.put(
    `/checkoutSessions/v2/${paymentIntentId}`,
    {
      ...body,
      eventId
    }
  );

  return data;
}

export async function inviteGroupToEvent({
  eventId,
  groupId
}: {
  eventId: string;
  groupId: string;
}): Promise<{ success: boolean }> {
  const { data } = await bounceApiClient.post(`/api/events/${eventId}/invite`, {
    groupId
  });

  return data;
}

export async function inviteGuestsToEvent({
  eventId,
  sourceEventId
}: {
  eventId: string;
  sourceEventId: string;
}): Promise<{ success: boolean }> {
  const { data } = await bounceApiClient.post(
    `/api/events/${eventId}/invites/hostedEvent`,
    { sourceEventId }
  );

  return data;
}

export async function confirmCheckoutSession({
  eventId,
  paymentIntentId,
  isWaiverAccepted,
  trackingId
}: ConfirmCheckoutSessionParams): Promise<void> {
  await bounceApiClient.post(
    `/api/events/${eventId}/checkout/${paymentIntentId}/confirm`,
    { trackingId, isWaiverAccepted }
  );
}
export async function confirmCheckoutSessionV2({
  eventId,
  paymentIntentId,
  isWaiverAccepted,
  trackingId
}: ConfirmCheckoutSessionParams): Promise<void> {
  await bounceApiClient.post(
    `/api/v2/events/${eventId}/checkout/${paymentIntentId}/confirm`,
    { trackingId, isWaiverAccepted }
  );
}

export async function requestTicketApproval({
  eventId,
  tierId,
  answers
}: RequestApprovalParams): Promise<void> {
  const { data } = await bounceApiClient.post(
    `/api/events/${eventId}/approvals`,
    {
      tierId,
      answers
    }
  );
  return data;
}

export async function deleteEvent(eventId: string): Promise<void> {
  return bounceApiClient.delete(`/api/hosts/events/${eventId}`);
}

export async function deleteOrganization(orgId: string): Promise<void> {
  return bounceApiClient.delete(`/api/groups/${orgId}`);
}

export async function getTierFromShareToken({
  eventId,
  shareToken
}: {
  eventId: string;
  shareToken: string;
}): Promise<{ tierId: string; tierName: string }> {
  const { data } = await bounceApiClient.get(
    `/api/events/${eventId}/tiers/${shareToken}`
  );

  return data;
}

export async function publicGetTierFromShareToken({
  eventId,
  shareToken
}: {
  eventId: string;
  shareToken: string;
}): Promise<{ tierId: string; tierName: string }> {
  const { data } = await bounceApiClient.get('/checkoutSessions/privateTier', {
    params: {
      eventId,
      shareToken
    }
  });

  return data;
}

export async function verifyTicket({
  eventId,
  qrCodeString
}: {
  eventId: string;
  qrCodeString: string;
}): Promise<VerifyTicketResultDto> {
  const { data } = await bounceApiClient.post<VerifyTicketResultDto>(
    `/api/hosts/events/${eventId}/scanTicket`,
    { qrCodeString }
  );

  return data;
}

export async function submitEventRating({
  eventId,
  body
}: {
  eventId: string;
  body: SubmitEventRatingBody;
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/api/events/${eventId}/rating`,
    data: body
  });

  return data;
}

export async function getSuggestedFriendsFromEvent(
  eventId: string
): Promise<SuggestedFriendsFromEventDto> {
  const { data } = await bounceApiClient.get<SuggestedFriendsFromEventDto>(
    `/api/events/${eventId}/suggestedFriends`
  );

  return data;
}

export async function getGuestTicket({
  eventId,
  ticketId
}: {
  eventId: string;
  ticketId: string;
}): Promise<TicketDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/hosts/events/${eventId}/tickets/${ticketId}`
  });

  return data;
}

export async function partiallyRefundTicket({
  eventId,
  claimerId,
  transactionId
}: {
  eventId: string;
  claimerId?: string;
  transactionId: string;
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/api/hosts/events/${eventId}/refunds/partial`,
    data: {
      claimerId,
      transactionId
    }
  });

  return data;
}

export async function fullyRefundTicket({
  eventId,
  transactionIds
}: {
  eventId: string;
  transactionIds: {
    purchaserTransactionId: string;
    subsequentTransactionIds: string[];
  };
}): Promise<GenericSuccessResponse> {
  const refundSubsequentPurchasePromises =
    transactionIds.subsequentTransactionIds.map((transactionId) =>
      bounceApiClient({
        method: 'post',
        url: `/api/hosts/events/${eventId}/refunds/full`,
        data: {
          transactionId
        }
      })
    );

  await Promise.all(refundSubsequentPurchasePromises);

  const { data } = await bounceApiClient({
    method: 'post',
    url: `/api/hosts/events/${eventId}/refunds/full`,
    data: {
      transactionId: transactionIds.purchaserTransactionId
    }
  });

  return data;
}

export async function uploadGalleryPhoto({
  eventId,
  blob,
  caption
}: {
  eventId: string;
  blob: Blob;
  caption: string;
}): Promise<GenericSuccessResponse> {
  const formData = new FormData();
  formData.append('image', blob);
  formData.append('caption', caption);

  const { data } = await bounceApiClient.post(
    `/api/events/${eventId}/gallery`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return data;
}

export async function deleteGalleryPhoto({
  eventId,
  photoId
}: {
  eventId: string;
  photoId: string;
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient({
    method: 'delete',
    url: `/api/events/${eventId}/gallery/${photoId}`
  });

  return data;
}

export async function uploadPhoto({
  eventId,
  blob
}: {
  eventId: string;
  blob: Blob;
}) {
  const formData = new FormData();
  formData.append('photo', blob);

  const { data } = await bounceApiClient.post(
    `/api/hosts/events/${eventId}/photo`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return data;
}

export async function deleteCoverPhoto(eventId: string) {
  const { data } = await bounceApiClient({
    method: 'delete',
    url: `/api/hosts/events/${eventId}/photo`
  });

  return data;
}

export async function uploadHypeVideo({
  eventId,
  videoId
}: {
  eventId: string;
  videoId: string;
}) {
  const { data } = await bounceApiClient.post(
    `/api/hosts/events/${eventId}/hypeVideo`,
    {
      videoId
    }
  );

  return data;
}

export async function deleteHypeVideo(eventId: string) {
  const { data } = await bounceApiClient({
    method: 'delete',
    url: `/api/hosts/events/${eventId}/hypeVideo`
  });

  return data;
}

export async function createUploadVideoUrl({
  uploadLength,
  uploadMetadata
}: {
  uploadLength: string;
  uploadMetadata: string;
}) {
  const { data } = await bounceApiClient.post(`api/uploads/videos/urls/tus`, {
    uploadLength,
    uploadMetadata
  });

  return data;
}

export async function validateEventUrl({
  eventId,
  shareToken
}: {
  eventId: string;
  shareToken: string;
}): Promise<EventForUser> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/api/events/${eventId}/invites/url`,
    data: {
      shareToken
    }
  });

  return data;
}

export async function getTicketInfoRequired(
  eventId: string
): Promise<TicketInfoRequiredResponse> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/events/${eventId}/ticketInfoRequired`
  });

  return data;
}

export async function createTrackingLink({
  eventId,
  name
}: {
  eventId: string;
  name: string;
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/api/events/${eventId}/trackingLinks`,
    data: {
      name
    }
  });

  return data;
}

export async function incrementTrackingLinkViews(
  eventId: string,
  trackingLinkId: string
): Promise<void> {
  try {
    await bounceApiClient({
      method: 'post',
      url: `/service/events/${eventId}/trackingLinks/views`,
      data: { trackingLinkId }
    });
  } catch (error) {
    captureException(error, {
      contexts: { params: { eventId, trackingLinkId } }
    });
  }
}

export async function incrementEventViews(eventId: string): Promise<void> {
  try {
    await bounceApiClient({
      method: 'post',
      url: `/service/events/${eventId}/views`
    });
  } catch (error) {
    captureException(error, {
      contexts: { params: { eventId } }
    });
  }
}

export async function getAnalytics(eventId: string): Promise<AnalyticsDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/events/${eventId}/analytics`
  });

  return data;
}

export async function getDiscountCodesAnalytics(
  eventId: string
): Promise<DiscountCodeAnalyticsDto[]> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/events/${eventId}/analytics/discountCodes`
  });

  return data;
}

export async function getDiscountCodes(
  eventId: string
): Promise<EventDiscountCodesDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/events/${eventId}/discountCodes`
  });

  return data;
}

export async function createDiscountCode({
  eventId,
  body
}: CreateDiscountCodeParams): Promise<DiscountCodeDto> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/api/events/${eventId}/discountCodes`,
    data: body
  });

  return data;
}

export async function updateDiscountCode({
  eventId,
  discountCodeId,
  body
}: UpdateDiscountCodeParams): Promise<DiscountCodeDto> {
  const { data } = await bounceApiClient({
    method: 'put',
    url: `/api/events/${eventId}/discountCodes/${discountCodeId}`,
    data: body
  });

  return data;
}

export async function deleteDiscountCode({
  eventId,
  discountCodeId
}: DeleteDiscountCodeParams): Promise<void> {
  return bounceApiClient.delete(
    `/api/events/${eventId}/discountCodes/${discountCodeId}`
  );
}

export async function textAllGuests({
  eventId,
  message
}: TextAllGuestsParams): Promise<TextAllGuestsDto> {
  return bounceApiClient.post('/api/hosts/text-all-guests', {
    eventId,
    message
  });
}

export async function putPayoutDestination({
  bankId,
  eventId
}: PutPayoutDestinationParams): Promise<void> {
  return bounceApiClient.put(`/api/hosts/events/${eventId}/payoutDestination`, {
    bankId
  });
}

export async function createEvent({
  event
}: {
  event: EventCreation;
}): Promise<Event> {
  const { data } = await bounceApiClient.post('/api/hosts/events', event);
  return data;
}

export async function editEvent({
  eventId,
  event
}: {
  eventId: string;
  event: EventEdit;
}): Promise<Event> {
  const { data } = await bounceApiClient.put(
    `/api/hosts/events/${eventId}`,
    event
  );
  return data;
}

export async function requestToPublishEvent({
  eventId,
  eventSeriesId,
  responseId
}: {
  eventId: string;
  eventSeriesId?: string;
  responseId?: string;
}): Promise<GenericSuccessResponse> {
  let endpoint: string;

  if (eventSeriesId) {
    endpoint = `/api/eventSeries/${eventSeriesId}/requestPublication`;
  } else {
    endpoint = `/api/hosts/events/${eventId}/requestPublication`;
  }

  const { data } = await bounceApiClient.post(
    endpoint,
    responseId && { responseId }
  );
  return data;
}

export async function submitRequestToPublishForm({
  formId,
  body
}: {
  formId: string;
  body: { [key: string]: unknown };
}): Promise<SubmitRequestToPublishFormDto> {
  const { data } = await bounceApiClient.post(
    `/api/forms/${formId}/responses`,
    { response: body }
  );
  return data;
}

export async function postEarlyPayout({
  eventId
}: PostEarlyPayoutParams): Promise<void> {
  return bounceApiClient.post(`/api/hosts/events/${eventId}/earlyPayout`);
}

export async function createComplimentaryTickets({ eventId, name, quantity }) {
  const { data } = await bounceApiClient.post(
    `api/events/${eventId}/tickets/complimentary`,
    { name, quantity }
  );
  return data;
}

export async function getComplimentaryTickets(eventId) {
  const { data } = await bounceApiClient.get(
    `api/events/${eventId}/tickets/complimentary`
  );
  return data;
}

export const DEFAULT_PAGE_LIMIT = 15;

export async function getRequestApprovals(options: {
  eventId: string;
  limit?: number;
  pageNumber?: number;
}): Promise<TierApprovals> {
  const { data } = await bounceApiClient.get(
    `api/events/${options.eventId}/approvals`,
    {
      params: {
        limit: options?.limit || DEFAULT_PAGE_LIMIT,
        pageNumber: options?.pageNumber
      }
    }
  );

  return data;
}

export async function updateRequestApprovalMutation(options?: {
  eventId: string;
  approvalId: string;
  status?: 'pending' | 'approved' | 'rejected';
  readStatus?: 'read' | 'unread';
  answers?: TierApprovalAnswer[];
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.put(
    `api/events/${options.eventId}/approvals/${options.approvalId}`,
    {
      status: options?.status,
      readStatus: options?.readStatus,
      answers: options?.answers
    }
  );

  return data;
}

export async function exportRequestApprovalList(
  eventId: string
): Promise<Blob> {
  const { data } = await bounceApiClient.get(
    `/api/events/${eventId}/approvals/export`,
    { responseType: 'blob' }
  );

  return data;
}

export async function getRequestApproval({ eventId, approvalId }) {
  const { data } = await bounceApiClient.get(
    `/api/events/${eventId}/approvals/${approvalId}`
  );

  return data;
}

export async function getEventEntryGuests(options: {
  eventId: string;
  limit?: number;
  pageNumber?: number;
}): Promise<GuestEntryList> {
  const { data } = await bounceApiClient.get(
    `/api/events/${options.eventId}/entry/guests`,
    {
      params: {
        limit: options?.limit || DEFAULT_PAGE_LIMIT,
        pageNumber: options?.pageNumber
      }
    }
  );

  return data;
}

export async function getEventEntryDetail({
  eventId,
  ticketId
}: {
  eventId: string;
  ticketId: string;
}): Promise<GuestEntryDto> {
  const { data } = await bounceApiClient.get(
    `/api/events/${eventId}/entry/guests/${ticketId}`
  );

  return data;
}

export async function admitGuest({
  eventId,
  ticketId
}: {
  eventId: string;
  ticketId: string;
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.put(
    `/api/events/${eventId}/entry/guests/${ticketId}/admit`
  );

  return data;
}

export async function createMoments({
  albumId,
  moments
}: {
  albumId: string;
  moments: CreateMoment[];
}): Promise<MomentDto[]> {
  const { data } = await bounceApiClient.post(
    `/api/albums/${albumId}/moments`,
    { moments }
  );

  return data;
}

export async function approveEventPublication({
  eventId,
  eventSeriesId,
  message
}: {
  eventId: string;
  eventSeriesId?: string;
  message?: string;
}): Promise<GenericSuccessResponse> {
  let endpoint: string;

  if (eventSeriesId) {
    endpoint = `/api/eventSeries/${eventSeriesId}/approvePublication`;
  } else {
    endpoint = `/api/events/${eventId}/approvePublication`;
  }

  const { data } = await bounceApiClient.post(endpoint, { message });

  return data;
}

export async function rejectEventPublication({
  eventId,
  eventSeriesId,
  message
}: {
  eventId: string;
  eventSeriesId?: string;
  message?: string;
}): Promise<GenericSuccessResponse> {
  let endpoint: string;

  if (eventSeriesId) {
    endpoint = `/api/eventSeries/${eventSeriesId}/rejectPublication`;
  } else {
    endpoint = `/api/events/${eventId}/rejectPublication`;
  }

  const { data } = await bounceApiClient.post(endpoint, { message });

  return data;
}

export async function createEventSeries({
  frequency,
  startDate,
  seriesEndDate,
  eventId
}: {
  frequency: RecurringFrequency;
  startDate: string;
  seriesEndDate: string;
  eventId: string;
}): Promise<RecurringEventDto> {
  const { data } = await bounceApiClient.post(
    `/api/events/${eventId}/recurring`,
    {
      frequency,
      startDate,
      seriesEndDate
    }
  );

  return data;
}

export async function updateEventSeries({
  frequency,
  startDate,
  seriesEndDate,
  eventId
}: {
  frequency: RecurringFrequency;
  startDate: string;
  seriesEndDate: string;
  eventId: string;
}): Promise<RecurringEventDto> {
  const { data } = await bounceApiClient.put(
    `/api/events/${eventId}/recurring`,
    {
      frequency,
      startDate,
      seriesEndDate
    }
  );

  return data;
}

export async function getEventRecurringSeries({
  eventId
}: {
  eventId: string;
}): Promise<RecurringEventDto> {
  const { data } = await bounceApiClient.get(
    `/api/events/${eventId}/recurring`
  );

  return data;
}
